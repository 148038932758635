.mantine-TextInput-label {
  font-weight: 600;
  line-height: 1.4;
}

.mantine-Textarea-label {
  font-weight: 600;
  line-height: 1.4;
}

.mantine-Select-label {
  font-weight: 600;
  line-height: 1.4;
}

.mantine-DatePickerInput-label {
  font-weight: 600;
  line-height: 1.4;
}